
@use '../abstract' as *;

/*-----------------------------------------------------------------------------------

    Theme Name: Educal – Online Learning and Education HTML5 Template
    Author: Theme Pure
    Support: basictheme@gmail.com
    Description: Educal – Online Learning and Education HTML5 Template
    Version: 1.0

-----------------------------------------------------------------------------------

/************ TABLE OF CONTENTS ***************

    01. Common CSS
    02. Header CSS
    03. Hero CSS
    04. Category CSS
    05. Banner CSS
    06. Course CSS
    07. Events CSS
    08. Price CSS
    09. Cta CSS
    10. Product CSS
    11. Services CSS
    12. About CSS
    13. What CSS
    14. Why CSS
    15. Counter CSS
    16. Testimonial CSS
    17. Blog CSS
    18. Slider CSS
    19. Brand CSS
    20. Teacher CSS
    21. Page Title CSS
    22. Contact CSS
    23. Error CSS
    24. Login CSS
    00. Footer CSS


**********************************************/
/*


*/

/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
*{
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
 body {
	font-family: $hind;
	font-size: 16px;
	font-weight: normal;
	color: $body-text-color;
	line-height: 26px;
}
@-moz-document url-prefix() {
	html {
	  scroll-behavior: auto !important;
	}
  }

a{
	text-decoration: none;
}

.w-img{
	& img{
		width: 100%;
	}
}
.m-img{
	& img{
		max-width: 100%;
	}
}


a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
	@include transition(.3s);
}
a:focus,
.button:focus {
	text-decoration: none;
	outline: none;
}
a:focus,
a:hover
{
	color: inherit;
	text-decoration: none;
}
a,
button {
	color: inherit;
	outline: none;
	border: none;
}
button:hover{
	cursor: pointer;
}
button:focus{outline: 0; border:0}
.uppercase {
	text-transform: uppercase;
}
.capitalize {
	text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	font-family: $bodyFont;
	color: $heading-color;
	margin-top: 0px;
	font-weight: 700;
	line-height: 1.2;
	@include transition(.3s);
}

h1 {
	font-size: 40px;
}
h2 {
	font-size: 36px;
}
h3 {
	font-size: 24px;
}
h4 {
	font-size: 20px;
}
h5 {
	font-size: 16px;
}
h6 {
	font-size: 14px;
}
ul {
	margin: 0px;
	padding: 0px;
}
li {
	list-style: none;
}
p {
	font-family: $bodyFont;
	font-size: 16px;
	font-weight: normal;
	color: $body-text-color;
	margin-bottom: 15px;
	line-height: 26px;
}

*::-moz-selection {
	background: $e-blue;
	color: $white;
	text-shadow: none;
}
::-moz-selection {
	background: $e-blue;
	color:$white;
	text-shadow: none;
}
::selection {
	background: $e-blue;
	color: $white;
	text-shadow: none;
}

/*--
    - Input Placeholder
-----------------------------------------*/
*::-moz-placeholder {
	color: $black;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: $black;
	font-size: 14px;
	opacity: 1;
}


/*--
    - Common Classes
-----------------------------------------*/
.fix {
    overflow:hidden
}
.clear{
    clear: both;
}

.f-left {
	float: left
}
.f-right {
	float: right
}

.z-index-1{
	z-index: 1;
}

.overflow-y-visible{
	overflow-x: hidden;
	overflow-y: visible;
}

.p-relative{
	position: relative;
}
.p-absolute{
	position: absolute;
}
/*--
    - Background color
-----------------------------------------*/
.grey-bg {
	background: $grey;
}
.grey-bg-2 {
	background: $grey-2;
}
.grey-bg-5 {
	background: $grey-5;
}

.blue-bg {
	background:$e-blue;
}
.blue-bg-3 {
	background:$e-blue-3;
}
.blue-bg-4 {
	background:$e-blue-4;
}

.pink-bg{
	background: $e-pink-3;
}

.purple-bg{
	background: $e-purple-2;
}

.green-bg{
	background: $e-green-2;
}

.white-bg {
	background:$white;
}
.black-bg {
	background: $black;
}
.footer-bg {
	background: $footer-bg;
}


// .primary-bg {
// 	background: #222;
// }

/*--
    - color
-----------------------------------------*/

.text-white h1,
.text-white h2,
.text-white h3,
.text-white h4,
.text-white h5,
.text-white h6,
.text-white p,
.text-white span,
.text-white li,
.text-white a {
	color: $white !important;
}

.white-color {
	color: $white;
}

.theme-color {
	color: $e-blue !important;
}
.black-color {
	color: $black;
}


// spacing

.pl-245{
	padding-left: 245px;
}

.pr-245{
	padding-right: 245px;
}

.mb-22{
	margin-bottom: 22px;
}

.ml-270{
	margin-left: 270px;
}

.mb--120{
	margin-bottom: -120px;
}

.mb--100{
	margin-bottom: -100px;
}

.mt--210{
	margin-top: -210px;
}

.mt--80{
	margin-top: -80px;
}

.mb-130{
	margin-bottom: -130px;
}

.section-padding{
	padding-left: 50px;
	padding-right: 50px;
	@media #{$xl}{
		padding-left: 30px;
		padding-right: 30px;
	}
	@media #{$xs}{
		padding-left: 0px;
		padding-right: 0px;
	}
}


// theme btn

.e-btn{
	display: inline-block;
	height: 50px;
	line-height: 52px;
	text-align: center;
	padding: 0 25px;
	color: $white;
	background: $e-blue;
	@include border-radius(4px);
	font-weight: 500;
	@include sentence-case();
	&:hover{
		color: $white;
		@include box-shadow(0px 10px 24px 0px rgba(4, 23, 118, 0.3));
	}
	&-2{
		height: 34px;
		line-height: 36px;
		padding: 0 20px;
		font-size: 14px;
		text-transform: capitalize;
	}
	&-3{
		padding: 0 28px;
	}
	&-4{
		height: 46px;
		line-height: 44px;
		border: 2px solid $e-blue;
		background: $e-blue;
		color: $white;
	}
	&-5{
		padding-left: 22px;
		padding-right: 22px;
	}
	&-6{
		padding-left: 60px;
		padding-right: 60px;
	}
	&-7{
		height: 44px;
		line-height: 46px;
	}
	&-border{
		height: 46px;
		line-height: 44px;
		border: 2px solid $border-4;
		color: $black;
		background: transparent;
		&:hover{
			background: $e-blue;
			border-color: $e-blue;
			color: $white;
		}
		&-2{
			height: 46px;
			line-height: 44px;
			border: 2px solid rgba($color: $white, $alpha: .3);
			background: transparent;
			padding: 0 20px;
			&:hover{
				background: $white;
				border-color: $white;
				color: $black;
			}
		}
	}
	&-white{
		height: 54px;
		line-height: 58px;
		background: $white;
		color: $black;
		padding: 0 45px;
		&:hover{
			color: $black;
		}
	}
}


/* link btn */

.link-btn{
	position: relative;
	font-size: 16px;
	color: $e-text-3;
	font-weight: 500;
	padding-right: 21px;
	display: inline-block;
	& i{
		font-size: 14px;
		position: absolute;
		top: 12px;
		@include transform(translateY(-50%));
		transition: all ease .2s;
		-webkit-transition: all ease .2s;
		-moz-transition: all ease .2s;
		-ms-transition: all ease .2s;
		-o-transition: all ease .2s;
		&:first-child{
			right: 10%;
			visibility: hidden;
			opacity: 0;
		}
		&:last-child{
			right: 0;
		}
	}
	&:hover{
		color: $e-blue;
		& i{
			&:first-child{
				right: 0;
				visibility: visible;
				opacity: 1;
			}
			&:last-child{
				right: -10%;
				visibility: hidden;
				opacity: 0;
			}
		}
	}
	&.link-prev{
		padding-right: 0;
		padding-left: 21px;
		& i{
			&:first-child{
				left: 10%;
				right: auto;
			}
			&:last-child{
				left: 0;
				right: auto;
			}
		}
		&:hover{
			& i{
				&:first-child{
					left: 0%;
					right: auto;
				}
				&:last-child{
					left: -10%;
					right: auto;
				}
			}
		}
	}
}

.link-btn-2{
	position: relative;
	font-size: 12px;
	font-weight: 500;
	color: $black;
	text-transform: uppercase;
	padding-right: 25px;
	width: 40px;
	height: 40px;
	display: inline-block;
	line-height: 36px;
	@include border-radius(50%);
	border: 2px solid rgba($color: $white, $alpha: .2);
	overflow: hidden;
	& i{
		position: absolute;
		top: 50%;
		@include transform(translateY(-50%));
		transition: all ease .2s;
		-webkit-transition: all ease .2s;
		-moz-transition: all ease .2s;
		-ms-transition: all ease .2s;
		-o-transition: all ease .2s;
		&:first-child{
			left: -100%;
			visibility: hidden;
			opacity: 0;

		}
		&:last-child{
			right: 35%;
		}
	}
	&:hover{
		color: $white;
		& i:first-child{
			left: 35%;
			visibility: visible;
			opacity: 1;
		}
		& i:last-child{
			right: -100%;
			visibility: hidden;
			opacity: 0;
		}
	}
}


// play btn
.play-btn{
	display: inline-block;
	width: 44px;
	height: 44px;
	text-align: center;
	line-height: 47px;
	@include border-radius(50%);
	background: $white;
	color: $e-blue;
  border: 1px solid $e-blue;
	-webkit-animation: pulse 2s infinite;
	-moz-animation: pulse 2s infinite;
	-o-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	font-size: 12px;
}

// section title

.section{
	&__sub-title{
		font-size: 18px;
		font-weight: 500;
		color: $e-blue;
		display: inline-block;
		margin-bottom: 5px;
	}
	&__title{
		font-size: 40px;
		//color: $white;
		text-transform: capitalize;
		z-index: 1;
		margin-bottom: 3px;

		@media #{$xs}{
			font-size: 33px;
		}
		& span{
			position: relative;
			& img{
				position: absolute;
				left: -14px;
				bottom: 12px;
				z-index: -1;
				-webkit-animation: section-animation 3s infinite;
				animation: section-animation 3s infinite;
			}
			&.yellow-bg-big{
				& img{
					bottom: 0px;
					width: 120%;
					-webkit-animation: section-animation-2 3s infinite;
					animation: section-animation-2 3s infinite;
				}
			}
			&.yellow-bg-sm{
				& img{
					bottom: 0px;
					width: 120%;
					-webkit-animation: section-animation-2 3s infinite;
					animation: section-animation-2 3s infinite;
				}
			}
		}
	}
	&__title-wrapper{
		position: relative;
		z-index: 1;
		& p{
			font-size: 16px;
			//color: $gray-200;
		}
	}
}

@keyframes section-animation {
	0% {
	  width: 0;
	}
	15% {
	  width: 100%;
	}
	85% {
	  opacity: 1;
	}
	90% {
	  width: 100%;
	  opacity: 0;
	}
	to {
	  width: 0;
	  opacity: 0;
	}
}

@keyframes section-animation-2 {
	0% {
	  width: 0;
	}
	15% {
	  width: 125%;
	}
	85% {
	  opacity: 1;
	}
	90% {
	  width: 125%;
	  opacity: 0;
	}
	to {
	  width: 0;
	  opacity: 0;
	}
}

/* basic pagination */

.basic-pagination{
	& ul{
		& li{
			display: inline-block;
			margin-right: 20px;
			@media #{$xs}{
				margin-right: 5px;
			}
			&.prev,
			&.next{
				& a{
					width: inherit;
					height: inherit;
					line-height: inherit;
					@include border-radius(0);
					border: none;
					&:hover{
						color: $e-blue;
						background: inherit;
					}
				}
			}
			&.active{
				& a{
					color: $white;
					background: $e-blue;
					border-color: $e-blue;
				}
			}
			& a{
				position: relative;
				overflow: hidden;
				background: transparent;
				display: inline-block;
				width: 40px;
				height: 40px;
				line-height: 38px;
				text-align: center;
				@include border-radius(4px);
				font-weight: 500;
				font-size: 16px;
				border: 2px solid #eef0f6;
				color: $black;
				&:hover{
					background: $e-blue;
					color: $white;
					border-color: $e-blue;
				}
			}
		}
	}
}



// overlay

 .body-overlay{
    background-color: rgba($color: #000000, $alpha: .5);
	height: 100%;
	width: 100%;
	position: fixed;
	top: 0;
	z-index: 99;
	left: 0;
	opacity: 0;
	visibility: hidden;
	@include transition(.3s);

	&:hover{
		cursor: pointer;
	}
}
.body-overlay.opened{
	opacity: 1;
    visibility: visible;
}


@media (min-width: 1400px){
	.container, .container-lg, .container-md, .container-sm,
	.container-xl, .container-xxl {
		max-width: 1200px;
	}
}
