// Colors ---------------

$blue:    #002b5b !default;
$indigo: #6100fd !default;
$purple: #8200ff !default;
$pink: #ff0074 !default;
$red: #ff0031 !default;
$orange: #ff7400 !default;
$yellow: #ffc000 !default;
$green: #006702 !default;
$teal: #00ffb5 !default;
$cyan: #dc00ff !default;

$primary-base: $blue;

$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #eee !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bbb !default;
$gray-600: lighten(desaturate($primary-base, 40), 4%) !default;
$gray-700: darken(desaturate($primary-base, 40), 0%) !default;
$gray-800: darken(desaturate($primary-base, 40), 8%) !default;
$gray-900: darken(desaturate($primary-base, 40), 12%) !default;
$black:    darken($primary-base, 60%) !default;

$primary:       $primary-base !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-500 !default;
$dark:          $gray-900 !default;
$background:    $white !default;
$foreground:    $gray-900 !default;
$heading:       darken($foreground, 8%);


$black: #0e1133;
$black-2: #121317;
$black-3: #000320;



// grey colors
$grey:#f3f4f8;
$grey-2:#edeef3;
$grey-3:#dbdfe9;
$grey-4:#c9ccd4;
$grey-5:#f5f6fa;
$grey-6:#e2ece9;
$grey-7:#f6f7fb;
$grey-8:#d8dae3;
$grey-9:#7a797f;
$grey-11:#eeedf2;
$grey-12:#f6f6f7;
$grey-13:#8e8c94;


// footer

$footer-bg: darken(#002b5b, 5);



// basic color
$e-blue: #002b5b;
$e-blue-2: lighten(#002b5b, 10);
$e-blue-3: lighten(#002b5b, 20);
$e-blue-4: lighten(#002b5b, 30);
$e-blue-5: lighten(#002b5b, 40);
$e-blue-6: lighten(#002b5b, 50);
$e-yellow: #ffb352;
$e-yellow-2: #ff9415;
$e-yellow-3: #ffa507;
$e-pink: #fc4389;
$e-pink-2: #f2277e;
$e-pink-3: #dd246e;
$e-pink-4: #fc4d93;
$e-pink-5: #ff4690;
$e-orange: #ff652e;
$e-orange-2: #fa7919;
$e-orange-3: #ff8f21;
$e-orange-4: #f7641e;
$e-purple: #b128ff;
$e-purple-2: #8007e6;
$e-purple-3: #8330fe;
$e-skyblue: #0fa0dd;
$e-skyblue-2: #14c0da;
$e-green: #30a820;
$e-green-2: #0cae74;
$e-brown: #960443;


// text colors
$body-text-color: #6d6e75;

$e-text-1: #8c8faa;
$e-text-2: #474956;
$e-text-3: #6d6e75;
$e-text-4: #53545b;
$e-text-5: #a1a2ab;
$e-text-6: #83848a;
$e-text-7: #8d8e9a;


//border colors
$border:#eef0f6;
$border-2:#f0f0f5;
$border-3:#e0e3ed;
$border-4:#d7d9e3;
$border-5:#1c1f3f;
$border-6:#e0e1e8;
$border-7:#d2d3dc;
$border-8:#edeef2;



// Heading Color
$heading-color: #002b5b;


// Soft color
$black-soft:#000;

$theme-colors: (
  blue: $blue,
  indigo: $indigo,
  purple: $purple,
  pink: $pink,
  red: $red,
  orange: $orange,
  yellow: $yellow,
  green: $green,
  teal: $teal,
  cyan: $cyan,
  white: $white,
  gray: $grey,
  gray-dark: $gray-800,
  primary: $primary-base,
  secondary: $secondary,
  success: $success,
  info: $info,
  warning: $warning,
  danger: $danger,
  light: $light,
  gray-100: $gray-100,
  gray-200: $gray-200,
  gray-300: $gray-300,
  gray-400: $gray-400,
  gray-500: $gray-500,
  gray-600: $gray-600,
  gray-700: $gray-700,
  gray-800: $gray-800,
  gray-900: $gray-900,
  dark: $dark
) !default;
