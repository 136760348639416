// Font Family
//@import url("https://use.typekit.net/fpj8xmd.css");
@import url('https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500&display=swap');

$hind: 'Hind', sans-serif;
//$hind: "vendetta", serif;
$fontawesome: 'Maven Pro', sans-serif;
//$hind: 'Maven Pro', sans-serif;
//$headerFont: 'Maven Pro', sans-serif; //futura-pt, sans-serif;
//$bodyFont: $hind //$headerFont;//nobel, sans-serif;
$bodyFont: 'Maven Pro', sans-serif;
$headerFont: 'Hind', sans-serif;
