@use '../abstract' as *;

._animate_dot_up {
  -webkit-animation: hero-dot-1 5s linear 0s infinite alternate;
  -moz-animation: hero-dot-1 5s linear 0s infinite alternate;
  -o-animation: hero-dot-1 5s linear 0s infinite alternate;
  animation: hero-dot-1 5s linear 0s infinite alternate;
}

._animate_dot_down {
  -webkit-animation: hero-dot-4 5s linear 0s infinite alternate;
  -moz-animation: hero-dot-4 5s linear 0s infinite alternate;
  -o-animation: hero-dot-4 5s linear 0s infinite alternate;
  animation: hero-dot-4 5s linear 0s infinite alternate;
}

._animate_dot_down2 {
  -webkit-animation: hero-dot-5 5s linear 0s infinite alternate;
  -moz-animation: hero-dot-5 5s linear 0s infinite alternate;
  -o-animation: hero-dot-5 5s linear 0s infinite alternate;
  animation: hero-dot-5 5s linear 0s infinite alternate;
}

._animate_dot_updown {
  -webkit-animation: hero-dot-3 5s linear 0s infinite alternate;
  -moz-animation: hero-dot-3 5s linear 0s infinite alternate;
  -o-animation: hero-dot-3 5s linear 0s infinite alternate;
  animation: hero-dot-3 5s linear 0s infinite alternate;
}

._animate_pulse {
  -webkit-animation: pulse 2s infinite;
  -moz-animation: pulse 2s infinite;
  -o-animation: pulse 2s infinite;
  animation: pulse 2s infinite;
}

@for $i from 0 through 10 {
  ._animate_pulse-#{$i} {
    -webkit-animation: pulse #{$i}s infinite;
    -moz-animation: pulse #{$i}s infinite;
    -o-animation: pulse #{$i}s infinite;
    animation: pulse #{$i}s infinite;
  }
}

._animate_bounce{
  -webkit-animation: hero-bounce 2s infinite;
  -moz-animation: hero-bounce 2s infinite;
  -o-animation: hero-bounce 2s infinite;
  animation: hero-bounce 2s infinite;
}

._animate_pulse_black {
  -webkit-animation: pulse-black 2s infinite;
  -moz-animation: pulse-black 2s infinite;
  -o-animation: pulse-black 2s infinite;
  animation: pulse-black 2s infinite;
}
