//backgroud
@mixin background($position: center,$size: cover,$repeat: no-repeat) {
  background: {
    position: $position;
    repeat: $repeat;
    size: $size;
  }
}

//transition
@mixin transition($time) {
  -webkit-transition: all $time ease-out 0s;
  -moz-transition: all $time ease-out 0s;
  -ms-transition: all $time ease-out 0s;
  -o-transition: all $time ease-out 0s;
  transition: all $time ease-out 0s;
}

// transform
@mixin transform($transforms) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  transform: $transforms;
}

//border
@mixin border-radius($man) {
  -webkit-border-radius: $man;
  -moz-border-radius: $man;
  border-radius: $man;
}

// sentence case
@mixin sentence-case() {
  text-transform: lowercase;
  &:first-letter {
    text-transform: uppercase;
  }
}

// Flexbox display
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

// Box shadows
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
  box-shadow: $shadow;
}

//before-left-top
@mixin before-left-top($width, $height, $background, $left, $top) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: $width;
    height: $height;
    background: $background;
    left: $left;
    top: $top;
  }
}

//before-left-bottom
@mixin before-left-bottom($width, $height, $background, $left, $bottom) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: $width;
    height: $height;
    background: $background;
    left: $left;
    bottom: $bottom;
  }
}

//before-right-top
@mixin before-right-bottom($width, $height, $background, $right, $bottom) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: $width;
    height: $height;
    background: $background;
    right: $right;
    bottom: $bottom;
  }
}

//before-right-bottom
@mixin before-right-top($width, $height, $background, $right, $top) {
  position: relative;
  &::before {
    position: absolute;
    content: '';
    width: $width;
    height: $height;
    background: $background;
    right: $right;
    top: $top;
  }
}

//after-left-top
@mixin after-left-top($width, $height, $background, $left, $top) {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: $width;
    height: $height;
    background: $background;
    left: $left;
    top: $top;
  }
}

//after-left-bottom
@mixin after-left-bottom($width, $height, $background, $left, $bottom) {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: $width;
    height: $height;
    background: $background;
    left: $left;
    bottom: $bottom;
  }
}

//after-right-top
@mixin after-right-bottom($width, $height, $background, $right, $bottom) {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: $width;
    height: $height;
    background: $background;
    right: $right;
    bottom: $bottom;
  }
}

//after-right-bottom
@mixin after-right-top($width, $height, $background, $right, $top) {
  position: relative;
  &::after {
    position: absolute;
    content: '';
    width: $width;
    height: $height;
    background: $background;
    right: $right;
    top: $top;
  }
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(255,255,255, 0);
    box-shadow: 0 0 0 45px rgba(255,255,255, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(255,255,255, 0);
    box-shadow: 0 0 0 0 rgba(255,255,255, 0);
  }
}

@-webkit-keyframes pulse-black {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 45px rgba(0,0,0, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}
@keyframes pulse-black {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
    box-shadow: 0 0 0 0 rgba(0,0,0, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 45px rgba(0,0,0, 0);
    box-shadow: 0 0 0 45px rgba(0,0,0, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0,0,0, 0);
    box-shadow: 0 0 0 0 rgba(0,0,0, 0);
  }
}
@keyframes hero-dot-1 {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes hero-dot-2 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(-100px);
  }
}

@keyframes hero-dot-3 {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(-100px);
  }
}

@keyframes hero-dot-4 {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(100px);
  }
}

@keyframes hero-dot-5 {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes hero-bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-30px);}
  60% {transform: translateY(-15px);}
}

@keyframes hero-circle-1{
  0%{
    @include transform(rotate(0deg));
  }
  100%{
    @include transform(rotate(360deg));
  }
}
