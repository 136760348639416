@import "colors";

@for $i from 10 through 78 {
  .text-#{$i} {
    font-size: #{$i}px !important;
  }
}

@for $i from 0 through 200 {
  .p-#{$i} {
    padding: #{$i}px !important;
  }
  .pt-#{$i} {
    padding-top: #{$i}px !important;
  }
  .pb-#{$i} {
    padding-bottom: #{$i}px !important;
  }
  .pl-#{$i} {
    padding-left: #{$i}px !important;
  }
  .pr-#{$i} {
    padding-right: #{$i}px !important;
  }
  .m-#{$i} {
    margin: #{$i}px !important;
  }
  .mt-#{$i} {
    margin-top: #{$i}px !important;
  }
  .mb-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .ml-#{$i} {
    margin-left: #{$i}px !important;
  }
  .mr-#{$i} {
    margin-right: #{$i}px !important;
  }

  .p--#{$i} {
    padding: -#{$i}px !important;
  }
  .pt--#{$i} {
    padding-top: -#{$i}px !important;
  }
  .pb--#{$i} {
    padding-bottom: -#{$i}px !important;
  }
  .pl--#{$i} {
    padding-left: -#{$i}px !important;
  }
  .pr--#{$i} {
    padding-right: -#{$i}px !important;
  }
  .m--#{$i} {
    margin: -#{$i}px !important;
  }
  .mt--#{$i} {
    margin-top: -#{$i}px !important;
  }
  .mb--#{$i} {
    margin-bottom: -#{$i}px !important;
  }
  .ml--#{$i} {
    margin-left: -#{$i}px !important;
  }
  .mr--#{$i} {
    margin-right: -#{$i}px !important;
  }
}

@for $i from 300 through 900 {
  .font-weight-#{$i} {
    font-weight: $i !important;
  }
}

@each $name, $value in $theme-colors {

  .bg-#{$name} {
    background: $value !important;
  }

  .text-#{$name} {
    color: $value !important;
  }

  .btn-outline-#{$name} {
    color: $value;
    border-color: $value;
    background: rgba($gray-200, .6);

    &:hover {
      background: lighten($value, 5%);
      border-color: lighten($value, 5%);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($value, 0.5)
    }
  }

  .btn-text-#{$name} {
    color: $value;
    border: none;
    background: rgba($gray-200, 0);

    &:hover {
      background: $value;
      border-color: $value;
      color: $white;
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($value, 0.5)
    }
  }

  .btn-#{$name} {
    background: $value;
    border-color: $value;

    &:hover {
      background: darken($value, 5%);
      border-color: darken($value, 5%);
    }

    &:focus {
      box-shadow: 0 0 0 0.2rem rgba($value, 0.5)
    }

    .btn-spinner {
      animation: btn-glow-#{$name} 1s ease infinite;
    }
  }
  @keyframes btn-glow-#{$name} {
    0% {
      box-shadow: 0 0 0 .4em darken($value, 10%), 0 0 0 .1em darken($value, 10%);
      transform: rotate(360deg);
    }
    50% {
      border-top-color: #ffffff;
    }
    100% {
      box-shadow: 0 0 0 .4em darken($value, 10%), 0 0 0 3.6em transparent;
    }
  }

  @for $i from 0 through 15 {
    .border-#{$name}-#{$i} {
      border: #{$i}px solid #{$name} !important;
    }
    .border-t-#{$name}-#{$i} {
      border-top: #{$i}px solid #{$name} !important;
    }
    .border-b-#{$name}-#{$i} {
      border-bottom: #{$i}px solid #{$name} !important;
    }
    .border-l-#{$name}-#{$i} {
      border-left: #{$i}px solid #{$name} !important;
    }
    .border-r-#{$name}-#{$i} {
      border-right: #{$i}px solid #{$name} !important;
    }
  }
}

@for $i from 0 through 25 {
  .border-radius-#{$i} {
    border-radius: #{$i}px #{$i}px #{$i}px #{$i}px !important;
  }
}

.text-align-left {
  text-align: left !important;
}

.text-align-right {
  text-align: right !important;
}

.text-align-justify {
  text-align: justify !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0px 30px 50px 0px rgba(1, 11, 60, 0.1) !important;
}
